import { createContext, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import type { AuthorizedUser, User, LoginRequest } from "../types/user";
import {
	AccountInfo,
	AuthenticationResult,
	PopupRequest,
	PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "../authConfig";
import Lockr from "lockr";
import { StorageKeys } from "../utils/storageKeys";
import { authService } from "../services/auth";

interface State {
	isInitialized: boolean;
	isAuthenticated: boolean;
	user: User | null;
}

interface AuthContextValue extends State {
	platform: "JWT";
	loginAAD: () => Promise<boolean>;
	verify: () => Promise<boolean>;
	logout: () => Promise<void>;
}

interface AuthProviderProps {
	children: ReactNode;
}

type InitializeAction = {
	type: "INITIALIZE";
	payload: {
		isAuthenticated: boolean;
		user: User | null;
	};
};

type LoginAADAction = {
	type: "LOGINAAD";
	payload: {
		user: User;
	};
};

type LogoutAction = {
	type: "LOGOUT";
};

type Action = InitializeAction | LoginAADAction | LogoutAction;

const initialState: State = {
	isAuthenticated: false,
	isInitialized: false,
	user: null,
};

const setAuthenticationResult = (
	authenticationResult: AuthenticationResult | null
): void => {
	if (authenticationResult) {
		localStorage.setItem(
			StorageKeys.AzureTenantId,
			authenticationResult.account.tenantId
		);

		localStorage.setItem(
			StorageKeys.AuthenticationResult,
			JSON.stringify(authenticationResult)
		);
		localStorage.setItem(StorageKeys.IdToken, authenticationResult.idToken);
	} else {
		localStorage.removeItem(StorageKeys.AuthenticationResult);
		localStorage.removeItem(StorageKeys.IdToken);
	}
};

const getAuthenticationResultFromStorage = (): AuthenticationResult | null => {
	const existingAuthenticationResult = localStorage.getItem(
		StorageKeys.AuthenticationResult
	);

	if (!existingAuthenticationResult) {
		return null;
	}

	try {
		return JSON.parse(existingAuthenticationResult) as AuthenticationResult;
	} catch (err) {
		console.error(
			`unable to deserialize value from existingAuthenticationResult. existingAuthenticationResult had value ${existingAuthenticationResult} and failed with value ${err} `
		);
		return null;
	}
};

const setSession = (accessToken: string | null): void => {
	if (accessToken) {
		localStorage.setItem(StorageKeys.AccessToken, accessToken);
	} else {
		localStorage.removeItem(StorageKeys.AccessToken);
	}
};

const handlers: Record<string, (state: State, action: Action) => State> = {
	INITIALIZE: (state: State, action: InitializeAction): State => {
		const { isAuthenticated, user } = action.payload;

		return {
			...state,
			isAuthenticated,
			isInitialized: true,
			user,
		};
	},
	LOGINAAD: (state: State, action: LoginAADAction): State => {
		const { user } = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user,
		};
	},
	LOGOUT: (state: State): State => ({
		...state,
		isAuthenticated: false,
		user: null,
	}),
};

const reducer = (state: State, action: Action): State =>
	handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
	...initialState,
	platform: "JWT",
	loginAAD: () => Promise.resolve(false),
	verify: () => Promise.resolve(false),
	logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
	const { children } = props;
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		const initialize = async (): Promise<void> => {
			try {
				const accessToken = window.localStorage.getItem(
					StorageKeys.AccessToken
				);
				if (accessToken) {
					setSession(accessToken);
					const authenticationResult = getAuthenticationResultFromStorage();

					const user = {
						id: authenticationResult.account.localAccountId,
						email: authenticationResult.account.username,
						name: authenticationResult.account.name,
					};

					dispatch({
						type: "INITIALIZE",
						payload: {
							isAuthenticated: true,
							user,
						},
					});
				} else {
					console.log(" user is not authenticated");
					dispatch({
						type: "INITIALIZE",
						payload: {
							isAuthenticated: false,
							user: null,
						},
					});
				}
			} catch (err) {
				console.log("authentication check failed");
				console.error(err);
				dispatch({
					type: "INITIALIZE",
					payload: {
						isAuthenticated: false,
						user: null,
					},
				});
			}
		};

		initialize();
	}, []);

	let loginRequest: PopupRequest;

	const loginAAD = async (): Promise<boolean> => {
		const msalObj = await getPublicClientApp();
		let loginSuccessfully = true;

		await msalObj
			.loginPopup(loginRequest)
			.then(async (resp: AuthenticationResult) => {
				handleResponse(resp);
			})
			.catch((err) => {
				console.error(err);
				loginSuccessfully = false;
			});

		return loginSuccessfully;
	};

	const verify = async (): Promise<boolean> => {
		let loginSuccessfully = true;

		await verifyUser()
			.then((authorizedUser: AuthorizedUser) => {
				if (authorizedUser?.userData?.id) {
					setUserTenantInfoToLocalStorage(authorizedUser);

					setSession(authorizedUser?.token);

					dispatch({
						type: "LOGINAAD",
						payload: {
							user: {
								id: authorizedUser.userData.id,
								email: authorizedUser.userData.email,
								name: authorizedUser.userData.displayName
									? authorizedUser.userData.displayName
									: authorizedUser.userData.name,
							},
						},
					});
				} else {
					loginSuccessfully = false;
				}
			})
			.catch((err) => {
				console.error(err);
				loginSuccessfully = false;
			});

		return loginSuccessfully;
	};

	const getPublicClientApp = async () => {
		const tenant = Lockr.get(StorageKeys.LoginTenant);

		if (tenant) {
			const copiedMsalConfig = JSON.parse(JSON.stringify(msalConfig));
			copiedMsalConfig.auth.authority = copiedMsalConfig.auth.authority.replace(
				"common",
				tenant
			);

			return PublicClientApplication.createPublicClientApplication(
				copiedMsalConfig
			);
		}

		const myMSALObj =
			await PublicClientApplication.createPublicClientApplication(msalConfig);
		return myMSALObj;
	};

	const setUserTenantInfoToLocalStorage = (authorizedUser: AuthorizedUser) => {
		Lockr.set(
			StorageKeys.TenantId,
			authorizedUser.tenantId ?? authorizedUser.userData.tenantId
		);
		Lockr.set(StorageKeys.TenantName, authorizedUser.tenantName);
	};

	const verifyUser = async (): Promise<AuthorizedUser> => {
		let authorizedUser: AuthorizedUser = null;

		try {
			const data: LoginRequest = {
				target: localStorage.getItem(StorageKeys.DeploymentTenantId),
				token: localStorage.getItem(StorageKeys.IdToken),
				azureTenantId: localStorage.getItem(StorageKeys.AzureTenantId),
				userTenantId: localStorage.getItem(StorageKeys.ParentTenantId),
			};
			const response = await authService.verifyUser(data);
			if (response) {
				authorizedUser = {
					userData: response.data.userData,
					tenantId: response.data.tenantId,
					tenantName: response.data.tenantName,
					token: response.data.token,
				} as AuthorizedUser;
			}
		} catch (err) {
			console.error(err);
		}

		return authorizedUser;
	};

	const signoutUser = async (): Promise<any> => {
		await authService.signout();
	};

	const handleResponse = (response: AuthenticationResult) => {
		setAuthenticationResult(response);
		const account = response.account;

		setLocalStorageData(account);
	};

	const setLocalStorageData = (account: AccountInfo) => {
		Lockr.set(StorageKeys.LocalAccountId, account.localAccountId);
		Lockr.set(StorageKeys.Email, account.username);
		Lockr.set(StorageKeys.Name, account.name);
	};

	const logout = async (): Promise<void> => {
		await signoutUser().then(() => {
			setSession(null);

			dispatch({ type: "LOGOUT" });
		});
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				platform: "JWT",
				loginAAD,
				verify,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
